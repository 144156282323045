import { isEmpty } from 'ramda';

const byCheapestPrice = (
  {
    cheapestOrigin: {
      price: {
        perTraveller: { amount: a },
      },
    },
  },
  {
    cheapestOrigin: {
      price: {
        perTraveller: { amount: b },
      },
    },
  },
) => a - b;

const getCheapestProperty = (properties) => {
  if (
    !properties.find(
      (p) =>
        p.cheapestOrigin &&
        p.cheapestOrigin.price?.perTraveller &&
        !isEmpty(p.cheapestOrigin.price.perTraveller),
    )
  ) {
    return properties[0];
  }

  const filtered = properties.filter(
    (p) =>
      Boolean(p.cheapestOrigin) &&
      Boolean(p.cheapestOrigin.price?.perTraveller) &&
      Boolean(p.cheapestOrigin.price.perTraveller.amount),
  );

  return filtered.sort(byCheapestPrice)[0];
};

export default getCheapestProperty;
