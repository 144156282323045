import React from 'react';
import { Box } from '@qga/roo-ui/components';
import CampaignTeaser from '@/components/Campaigns/Teaser';
import useHomePage from '../../hooks/useHomePage';

const Campaigns = React.memo(() => {
  const { bodyCampaigns } = useHomePage();
  const key = (campaign) => `${campaign.slug}_${campaign.startDate}`;

  return (
    <>
      {bodyCampaigns.map((campaign) => (
        <Box key={key(campaign)} my={['8', null, '6']}>
          <CampaignTeaser campaign={campaign} />
        </Box>
      ))}
    </>
  );
});

export default Campaigns;
