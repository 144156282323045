import React from 'react';
import styled from '@emotion/styled';
import { Flex, Image } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import Heading from '@/components/Heading';
import getAsset from '@/utils/getAsset';
import Link from '@/components/Link';

const StyledFlex = styled(Flex)`
  border-radius: ${themeGet('radii.md')};
  background: linear-gradient(
    -45deg,
    rgb(255, 134, 0) 0%,
    rgb(255, 81, 21) 100%
  );
`;

const StyledButton = styled(Link.Button)`
  border-color: ${themeGet('colors.white')};
  color: ${themeGet('colors.white')};
  min-width: 200px;

  &:hover,
  &:active,
  &:focus {
    border-color: ${themeGet('colors.white')};
    background-color: transparent;
  }
`;

const CallBanner = React.memo(() => (
  <StyledFlex
    p={['3', null, '6']}
    boxShadow="light"
    flexDirection={['column', null, 'row']}
    alignItems={[null, null, 'center']}
  >
    <Flex mb={['4', null, 'unset']} alignItems="flex-start">
      <Image
        src={getAsset('/images/icons/ic_livechat.svg')}
        alt="Chat icon"
        width="50px"
      />
      <Heading.h5 color="background.page" px={['3', null, '5']}>
        Speak to a dedicated holiday expert today for all the information you
        need to book or make changes to your trip.
      </Heading.h5>
    </Flex>
    <StyledButton href="/contact-us" outline>
      Contact us
    </StyledButton>
  </StyledFlex>
));

export default CallBanner;
