import { dataLayer } from '@/v2/utils/dataLayer';

const homePageDataLayer = () => {
  return dataLayer.push({
    event: 'pageView',
    pageType: 'Home Page',
  });
};

export default homePageDataLayer;
