import React from 'react';
import Text from '@/components/Text';
import useHomePage from '../../hooks/useHomePage';

const TermsConditions = React.memo(() => {
  const { hero } = useHomePage();

  return (
    <Text hint textStyle="small">
      {hero.termsConditions}
    </Text>
  );
});

export default TermsConditions;
