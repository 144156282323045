import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex, Icon } from '@qga/roo-ui/components';
import StarRating from '@/libs/v2/components/StarRating';
import ProcuredOffer from '@experiences-ui/shared/propTypes/ProcuredOffer';
import Heading from '@/components/Heading';
import Text from '@/components/Text';
import PropertyTags from '@/components/PropertyTags';
import PropertyContent from '../../propTypes/PropertyContent';
import { RATING_TYPE } from '@experiences-ui/shared/constants';

/**
 * @deprecated Use PropertyTitle.tsx
 *
 * /apps/jetstar-holidays/src/v2/components/ui/PropertyTitle/PropertyTitle.tsx
 *
 * import PropertyTitle from '@/v2/components/ui/PropertyTitle';
 *
 * propertyContent is no longer passed into this component, only pass what properties are required
 * procuredOffer is no longer passed into this component, it has no need for concern about the procuredOffer and showing the offer description.
 */
const PropertyTitle = ({
  propertyContent,
  withTags,
  withDestination,
  isTile,
  procuredOffer,
}) => {
  const { name, rating, ratingType } = propertyContent;
  const tags = propertyContent.tags || [];

  return (
    <>
      {withDestination && (
        <Box mb="2">
          <Text bold color="text.hint" fontSize="xs">
            <Icon name="holiday" size={24} mt="-6px" mr="4px" />
            {propertyContent.destination.title}
          </Text>
        </Box>
      )}
      <Heading.h4 fontSize={['md', null, 'lg']}>{name}</Heading.h4>
      <Flex
        mt="1"
        mb="3"
        alignItems={[null, null, 'center']}
        flexDirection={['column', null, 'row']}
        minHeight="21px"
      >
        <StarRating
          data-testid="STAR_RATING"
          rating={rating}
          ratingType={ratingType}
          size={ratingType === RATING_TYPE.AAA ? 20 : 14}
        />
        {withTags && !isTile && (
          <Box mt={[0, null, 0]} ml={[null, null, 4]} mb="1px">
            <PropertyTags
              propertyContent={propertyContent}
              tags={tags}
              size={3}
            />
          </Box>
        )}
      </Flex>
      <Box mb="2">
        {withTags && isTile ? (
          <PropertyTags
            propertyContent={propertyContent}
            tags={tags}
            size={3}
          />
        ) : (
          procuredOffer &&
          procuredOffer.offerDescription && (
            <Text medium lineHeight="24px">
              {procuredOffer.offerDescription}
            </Text>
          )
        )}
      </Box>
    </>
  );
};

PropertyTitle.defaultProps = {
  withTags: false,
  withDestination: true,
  isTile: false,
  procuredOffer: {},
};

PropertyTitle.propTypes = {
  propertyContent: PropTypes.shape(PropertyContent).isRequired,
  withTags: PropTypes.bool,
  withDestination: PropTypes.bool,
  isTile: PropTypes.bool,
  procuredOffer: PropTypes.shape(ProcuredOffer),
};

export default PropertyTitle;
