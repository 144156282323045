import { isNilOrEmpty } from '@/lib/object';

const showPropertyPrice = (featuredProperty = {}) =>
  (featuredProperty &&
    featuredProperty.cheapestOrigin &&
    featuredProperty.cheapestOrigin.price?.perTraveller &&
    !isNilOrEmpty(featuredProperty.cheapestOrigin.price.perTraveller.amount)) ||
  false;

export default showPropertyPrice;
