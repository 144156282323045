import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Box, Flex, Heading } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import Link from 'next/link';
import { css } from '@emotion/core';
import Button from '@/components/Button';
import Text from '@/components/Text';
import getDestinationRoute from '@/utils/getDestinationRoute';
import PropertyTitle from '@/components/PropertyTitle';
import PropertyContent from '../../../../../../propTypes/PropertyContent';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { isSanityImage } from '@experiences-ui/shared/utils';
import { SanityImage } from '../../../../../../../../../libs/shared/components';

const ViewButton = styled(Button)``;

const Card = styled(Flex)`
  cursor: pointer;

  &:focus,
  &:hover {
    ${ViewButton} {
      border-color: transparent;
      color: #fff;
      background-color: ${themeGet('colors.hover')};
    }
  }
`;

const BackgroundImage = styled(Box)`
  height: 240px;
  display: block;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

const AvailabilityPanel = styled(Box)`
  margin-top: auto;
  border-top: 1px solid ${themeGet('colors.greys.smoke')};
`;

const Truncate = styled(Box)`
  overflow: hidden;

  ${({ lines }) => css`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${lines < 1 ? 'unset' : lines};
  `}
`;

const Item = ({ propertyContent }) => {
  // eslint-disable-next-line max-len
  const href = `/${getDestinationRoute(
    propertyContent.destination.name,
  )}/properties/${propertyContent.id}/package`;

  const [isImageEnabled, setIsImageEnabled] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsImageEnabled(false);
    const urlHasFeature = router.asPath.includes('new-image');

    if (urlHasFeature) {
      setIsImageEnabled(true);
    }
  }, [router.asPath]);

  const SliderImage = () => {
    if (isImageEnabled) {
      return isSanityImage(propertyContent.mainImage.medium) ? (
        <SanityImage
          data-testid={'SANITY_SLIDER_IMAGE'}
          image={propertyContent.mainImage.medium}
          alt={propertyContent.name}
          objectFit="cover"
        />
      ) : (
        <Image
          data-testid="NEXT_SLIDER_IMAGE"
          src={propertyContent.mainImage.medium}
          alt={propertyContent.name}
          layout="fill"
          objectFit="cover"
        />
      );
    }

    return (
      <BackgroundImage
        backgroundImage={propertyContent.mainImage.medium}
        role="img"
        aria-label={propertyContent.name}
      />
    );
  };

  return (
    <Link href={href}>
      <Card
        flexDirection="column"
        height="628px"
        backgroundColor="background.card"
        width={['100%', null, '288px']}
      >
        <Box height={'240px'} position={'relative'}>
          <SliderImage />
        </Box>
        <Flex p="4" flexDirection="column" flex="1">
          <PropertyTitle propertyContent={propertyContent} withTags isTile />
          <Truncate lines={3}>
            <Text>{propertyContent.tagline}</Text>
          </Truncate>
          <AvailabilityPanel>
            <Flex mt="6" justifyContent="flex-end" alignItems="center">
              <Heading.h5 color="primary" fontSize="sm">
                Flights + stay
              </Heading.h5>
            </Flex>
            <Flex mt="2" justifyContent="flex-end" alignItems="center">
              <ViewButton block outline>
                View package options
              </ViewButton>
            </Flex>
          </AvailabilityPanel>
        </Flex>
      </Card>
    </Link>
  );
};

Item.propTypes = {
  propertyContent: PropTypes.shape(PropertyContent).isRequired,
};

export default Item;
