import type { UI } from '@/v2/types';

import { createCtx } from '@/v2/utils/createCtx';

interface ReturnType {
  campaigns: Array<UI.Campaign>;
  homePage: UI.Home;
}

export const [useHome, HomeProvider] = createCtx<ReturnType>();
