import BlockContent from '@sanity/block-content-to-react';
import React from 'react';
import { Box } from '@qga/roo-ui/components';
import { useAppSettings } from '@/components/AppSettingsProvider';
import { useApp } from '@/v2/contexts/app';
import Link from '@/components/Link';
import withProps from '@/components/withProps';
import Heading from '@/components/Heading';

import { brandColors } from '@/lib/theme/colors';
import textStyles from '@/lib/theme/textStyles';

const ImportantInformation = () => {
  const app = useAppSettings();
  const appV2 = useApp();

  const importantInformation = app.importantInformation
    ? app.importantInformation
    : appV2.importantInformation;

  return (
    <Box color="text.hint" pb="10">
      <Heading.h5>Important information</Heading.h5>
      <BlockContent
        blocks={importantInformation}
        serializers={{
          types: {
            block: withProps(({ node }) => ({
              ...(node.style === 'title' && textStyles.heading),
              ...(node.style === 'normal' && {
                fontSize: 'sm',
              }),
              color: brandColors.iron,
              my: '3',
            }))(Box),
          },
          marks: {
            link: withProps(({ mark }) => ({
              href: mark.href,
              external: mark.external,
              color: brandColors.blue,
            }))(Link.Text),
          },
        }}
      />
    </Box>
  );
};

export default ImportantInformation;
