const getPropertyFromDestination = (propertyContent) => {
  if (!propertyContent.destination || !propertyContent.destination.properties) {
    return null;
  }

  return propertyContent.destination.properties.find(
    (property) => property.id === propertyContent.id,
  );
};

export default getPropertyFromDestination;
