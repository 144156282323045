import React from 'react';
import styled from '@emotion/styled';

export const NavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 2px; // prevents button outlines from getting cut off when clicked
`;

const Navigation = ({
  groups,
  LeftButton,
  RightButton,
  Indicator,
}: {
  groups: number[];
  LeftButton: () => JSX.Element;
  RightButton: () => JSX.Element;
  Indicator: (props: {
    snapPointPosition: number;
    index: number;
  }) => JSX.Element;
}) => (
  <NavigationContainer data-testid="NAVIGATION_CONTROLLER">
    <LeftButton />
    {groups.map((value, index) => (
      <Indicator index={index} key={index} snapPointPosition={index} />
    ))}
    <RightButton />
  </NavigationContainer>
);

export default Navigation;
