import type { UI } from '@/v2/types';

import { useState } from 'react';
import { Box, Flex, Icon } from '@qga/roo-ui/components';

import translations from '@/v2/locales/en/home.json';

import { DestinationCard } from '@/v2/components/ui/DestinationCard';

// Legacy
import Heading from '@/v2/../components/Heading';
import LinkButton from '@/v2/../components/LinkButton';
import Text from '@/v2/../components/Text';
import useBreakpoints from '@/v2/../hooks/useBreakpoints';

interface Props {
  destinations: UI.Home['destinations'];
  heading: string;
}

const Destinations = ({ destinations, heading }: Props) => {
  const { xs, sm } = useBreakpoints();
  const [viewAllDestinations, setViewAllDestinations] = useState(false);

  const visibleDestinations = 4;

  return (
    <>
      <Box mb="4">
        <Heading.h2 fontSize={['md', null, '2xl']}>{heading}</Heading.h2>
      </Box>
      <Flex flexWrap="wrap">
        {destinations.map((destination, i) => {
          const show = xs || sm ? i < visibleDestinations : true;

          return (
            (show || viewAllDestinations) && (
              <Box
                key={destination.title}
                mb="4"
                pr={[null, null, '4']}
                width={['100%', null, '25%']}
              >
                <DestinationCard {...destination} index={i} />
              </Box>
            )
          );
        })}
      </Flex>
      {(xs || sm) && destinations.length > visibleDestinations && (
        <Box textAlign="center">
          <LinkButton onClick={() => setViewAllDestinations((v) => !v)}>
            <Flex>
              {
                <>
                  <Text bold link>
                    {viewAllDestinations
                      ? translations.sections.destinations.showLess
                      : translations.sections.destinations.showMore}
                  </Text>
                  <Icon
                    name={viewAllDestinations ? 'expandLess' : 'expandMore'}
                  />
                </>
              }
            </Flex>
          </LinkButton>
        </Box>
      )}
    </>
  );
};

export default Destinations;
