import { useContext, useMemo, createContext } from 'react';
import { useHome } from '@/v2/contexts/home';

const HomePageContext = createContext();
const HomePageProvider = HomePageContext.Provider;

/*
  JetStar Holidays HomePage:
  - Hero banner can show default banner or a 'Hero' campaign
  - Max of 2 campaigns are shown in the content area (excluding the Hero campaign)
*/
const useHomePage = () => {
  const v1 = useContext(HomePageContext);
  const v2 = useHome();

  const { campaigns, homePage } = v1 || v2;

  const { bodyCampaigns } = useMemo(() => {
    const [first, ...rest] = campaigns;
    const isHero = first && first.homePageHero && first.homePageHero.title;

    return {
      bodyCampaigns: isHero ? rest : campaigns,
    };
  }, [campaigns]);

  return {
    ...homePage,
    campaigns,
    bodyCampaigns,
  };
};

export { HomePageContext, HomePageProvider };
export default useHomePage;
