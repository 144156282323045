import getPropertyFromDestination from '../getPropertyFromDestination';

const getTagsFromPropertyContent = (propertyContent) => {
  const matchedProperty = getPropertyFromDestination(propertyContent);

  if (!matchedProperty || !matchedProperty.tags) {
    return [];
  }

  return matchedProperty.tags;
};

export default getTagsFromPropertyContent;
