import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Box, Flex } from '@qga/roo-ui/components';
import { head } from 'ramda';
import Button from '@/components/Button';
import Destination from '@/propTypes/Destination';
import Campaign from '@/propTypes/Campaign';
import FormControl from '@/components/FormControl';
import Travellers from '@/components/Travellers';
import DateRange from '@/components/DateRange';
import { filterCampaignsByDestination } from '@/utils/campaigns';
import useSearchForm, {
  NOT_AVAILABLE_ROUTE_ERROR,
} from '@/hooks/useSearchForm';
import Heading from '@/components/Heading';
import Route from './components/Route';
import RouteErrorMessage from '../../../RouteErrorMessage';
import useRouteDataLayer from './hooks/useRouteDataLayer';
import { mapOccupants } from '@/libs/utils';

const SearchButton = styled(Button)`
  min-width: 168px;
  height: 48px;
`;

const SearchForm = ({
  campaigns,
  fromDestinations,
  toDestinations,
  isHomeSearch = false,
  isSearchPage = false,
}) => {
  const {
    data,
    error,
    changeRoute,
    changeDateRange,
    changeTravellers,
    submit,
  } = useSearchForm({ isHomeSearch, isSearchPage });

  const [submitError, setSubmitError] = useState(false);
  const [originFocused, setOriginFocused] = useState(false);
  const [calendarFocused, setCalendarFocused] = useState(false);

  const { departureDate, returnDate, destination, origin } = data;

  // Can be undefined on first render as state hasn't initialised
  const occupants = data.occupants && mapOccupants(data.occupants);

  useRouteDataLayer(
    origin && origin.name,
    destination && destination.title,
    error.route,
  );

  const blackoutDates = useMemo(
    () => destination && origin && destination.blackoutDates[origin.code],
    [destination, origin],
  );

  const minimumNights = useMemo(
    () => destination && destination.searchConfig.minimumNights,
    [destination],
  );

  const filteredDestinations = useMemo(
    () =>
      origin
        ? toDestinations
            .map((state) => ({
              ...state,
              destinations: state.destinations.filter((d) =>
                d.searchConfig.originCodes.find((o) => o === origin.code),
              ),
            }))
            .filter((state) => state.destinations.length > 0)
        : toDestinations,
    [origin, toDestinations],
  );

  const campaign =
    destination &&
    head(filterCampaignsByDestination(campaigns, destination.name));

  const onChangeRoute = (event) => {
    setSubmitError(false);
    changeRoute(event);
  };

  const submitForm = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (Object.values(error).every((v) => !v)) {
      submit();
    } else {
      setSubmitError(true);
    }
  };

  return (
    <form data-testid="SEARCH_FORM">
      <Box mb={4}>
        <Heading.h4 fontSize={['md', null, 'lg']}>
          Search for your holiday package
        </Heading.h4>
      </Box>
      <Flex flexDirection={['column', null, 'row']}>
        <Box flex="1">
          <Route
            fromDestinations={fromDestinations}
            toDestinations={filteredDestinations}
            route={data}
            onDataChange={onChangeRoute}
            onOriginFocus={setOriginFocused}
            originFocused={originFocused}
            error={calendarFocused || submitError || error.route ? error : {}}
          />
        </Box>
        <Box m={2} />
        <Box>
          <DateRange
            initialDates={{ departureDate, returnDate }}
            originCode={origin && origin.code}
            destinationCode={
              destination && destination.searchConfig.destinationCode
            }
            onDataChange={changeDateRange}
            blackoutDates={blackoutDates}
            minimumNights={minimumNights}
            campaign={campaign && campaign}
            error={submitError ? error : {}}
            onCalendarFocus={setCalendarFocused}
          />
        </Box>
        <Box m={2} />
        <Box>
          <Travellers
            occupants={occupants}
            onSubmit={changeTravellers}
            error={submitError ? error : {}}
          />
        </Box>
        <Box m={['0', null, '3']} mb={4} />
        <FormControl flex={[1, 0, 0]} htmlFor="submit" label="">
          <SearchButton
            id="submit"
            variant="primary"
            onClick={submitForm}
            mt={[null, null, 6]}
          >
            Search
          </SearchButton>
        </FormControl>
      </Flex>
      {submitError && error.route === NOT_AVAILABLE_ROUTE_ERROR && (
        <Box>
          <RouteErrorMessage destination={destination} />
        </Box>
      )}
    </form>
  );
};

SearchForm.propTypes = {
  fromDestinations: PropTypes.arrayOf(PropTypes.shape(Destination)).isRequired,
  toDestinations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      destinations: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.shape(Campaign)).isRequired,
};

export default SearchForm;
