/* eslint-disable react/jsx-props-no-spreading */
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next';

// eslint-disable-next-line no-use-before-define
import React from 'react';
import { getHomePage } from '@/v2/services/bff/getHomePage';

import { HomeProvider } from '@/v2/contexts/home';

import { Meta } from '@/v2/components/global/Meta';
import { HomeView } from '@/v2/components/pages/HomeView';

export const getStaticProps = async ({ preview }: GetStaticPropsContext) => {
  const props = await getHomePage({
    preview,
  });

  return {
    props,
    revalidate: 300,
  };
};

const HomePage = ({
  campaigns,
  homePage,
  meta,
  ...rest
}: InferGetStaticPropsType<typeof getStaticProps>) => (
  <HomeProvider value={{ campaigns, homePage }}>
    <Meta {...meta} preferenceAppTitle={true} />
    <HomeView campaigns={campaigns} homePage={homePage} {...rest} />
  </HomeProvider>
);

export default HomePage;
