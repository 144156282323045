import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { NakedButton, Icon } from '@qga/roo-ui/components';
import { useSlider } from '@/libs/v2/components/Slider';
import { rem } from 'polished';
import { NavigationContainer } from '@/libs/v2/components/Slider/components/Navigation/Navigation';

/*
 *  Because Jetstar has different styles to Qantas, you can import and use these
 *  Jetstar specific components when initialising the Slider, eg.
    <SliderProvider>
      <Slider
        Wrapper={Wrapper}
        Container={Container}
        Indicator={Indicator}
        LeftButton={LeftButton}
        RightButton={RightButton}
      >
        <Item... />
      </Slider>
    </SliderProvider>
 */

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Button = styled(NakedButton)`
  position: relative;
  border-radius: ${themeGet('radii.rounded')};
  height: ${rem('24px')};
  width: ${rem('24px')};
  background-color: ${({ colorMode }: { colorMode: string }) =>
    colorMode === 'light' ? 'white' : themeGet('colors.greys.charcoal')};
  box-shadow: ${themeGet('shadows.light')};
`;

export const ArrowControl = ({
  direction,
  onClick,
  colorMode,
  disabled,
  ...rest
}: {
  direction: 'left' | 'right';
  title: string;
  disabled: boolean;
  onClick: Function;
  colorMode: string;
}) => {
  let iconColor = colorMode === 'light' ? 'icon.standAlone' : 'white';
  if (disabled) iconColor = 'icon.disabled';

  return (
    <Button
      onClick={onClick}
      colorMode={colorMode}
      aria-label={direction}
      disabled={disabled}
      {...rest}
    >
      <StyledIcon
        color={iconColor}
        size={24}
        name={direction === 'left' ? 'chevronLeft' : 'chevronRight'}
      />
    </Button>
  );
};

export const LeftButton = () => {
  const { scrollPrevious, previousDisabled } = useSlider();

  return (
    <ArrowControl
      direction="left"
      title="PREVIOUS"
      colorMode="light"
      onClick={() => scrollPrevious()}
      disabled={previousDisabled}
    />
  );
};

export const RightButton = () => {
  const { scrollNext, nextDisabled } = useSlider();

  return (
    <ArrowControl
      direction="right"
      title="NEXT"
      colorMode="light"
      onClick={() => scrollNext()}
      disabled={nextDisabled}
    />
  );
};

export const Indicator = ({
  snapPointPosition,
  index,
}: {
  snapPointPosition: number;
  index: number;
}) => {
  const { selectedIndex } = useSlider();

  const isCurrentIndicatorDisabled = index !== selectedIndex;

  return (
    <Icon
      key={snapPointPosition}
      index={index}
      title="INDICATOR"
      name="circle"
      disabled={isCurrentIndicatorDisabled}
      size={10.666}
      color={isCurrentIndicatorDisabled ? 'icon.disabled' : 'icon.inclusion'}
    />
  );
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 24px;
  padding-top: 24px;
  padding-left: 7px;
  padding-bottom: 10px;
  margin-top: -12px;

  ${NavigationContainer} {
    // this is to balance out the padding-bottom: 10px above.
    // this allows for consistent padding to exist at the bottom of the wrapper,
    // whether the Navigation component is rendered or not.
    margin-bottom: -10px;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 28px;
  margin-right: 13px;

  > div {
    display: flex;
    flex: 0 0 auto;
    min-width: 0;
    max-width: 90%;
  }
`;
