import type { UI } from '@/v2/types';

import styled from '@emotion/styled';
import { Box, Container, Flex } from '@qga/roo-ui/components';
import { SanityImage } from '@experiences-ui/shared/components';

// legacy components
import Heading from '../../../../components/Heading';
import Link from '../../../../components/Link';
import PromoPill from '../../../../components/PromoPill';
import Text from '../../../../components/Text';

interface Props extends UI.Hero {}

const StyledPromoPillWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  z-index: 1;
`;

const Hero = ({ cta, description, image, tag, title }: Props) => {
  return (
    <Box height={[null, null, '420px']} position="relative">
      <Box
        bottom="0"
        left="0"
        position={['relative', null, 'absolute']}
        right="0"
        top="0"
        height={['220px', null, '100%']}
      >
        <SanityImage
          data-testid="SANITY_BACKGROUND_IMAGE"
          image={image}
          alt={title}
          objectFit="cover"
          role="img"
        />
      </Box>
      <Container gutter={['0', null, 'default']} px={[null, null, '3']}>
        <Box
          bg="primary"
          borderRadius={[null, null, 'md']}
          height={[null, null, '308px']}
          position="relative"
          pt={['3', null, tag ? '2' : '0']}
          top={[null, null, '48px']}
          width={[null, null, '420px']}
        >
          {tag && (
            <StyledPromoPillWrapper>
              <PromoPill
                background="white"
                icon="watchLater"
                message={tag}
                textColor="text.body"
              />
            </StyledPromoPillWrapper>
          )}
          <Flex
            alignItems="stretch"
            flexDirection="column"
            height="100%"
            p={['3', null, '6']}
          >
            <Flex alignItems="center">
              <Heading.h1
                color="white"
                display="inline"
                fontSize={['lg', null, '4xl']}
              >
                {title}
              </Heading.h1>
            </Flex>
            <Box mt={2}>
              <Text bold color="white">
                {description}
              </Text>
            </Box>
            {cta && (
              <Link.Button
                as={cta.slug}
                data-testid="HERO_CTA"
                external={false}
                href={cta.slug}
                inverse
                mt={['3', null, 'auto']}
                outline
                shallow={false}
              >
                {cta.label}
              </Link.Button>
            )}
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
