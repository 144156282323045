import React, {
  Children,
  Key,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import useEmblaCarousel, {
  EmblaCarouselType,
  UseEmblaCarouselType,
} from 'embla-carousel-react';
import { EmblaEventType } from 'embla-carousel/components/EventHandler';

interface SliderContextType {
  ref?: UseEmblaCarouselType[0];
  emblaApi?: UseEmblaCarouselType[1];
  onSelect: (emblaApi: EmblaCarouselType, evt?: EmblaEventType) => void;
  onInit: (emblaApi: EmblaCarouselType, evt?: EmblaEventType) => void;
  groups: number[];
  selectedIndex: number;
  scrollNext: Function;
  nextDisabled: boolean;
  scrollPrevious: Function;
  previousDisabled: boolean;
  toggleActiveWhenScrollable: (
    emblaApi: EmblaCarouselType,
    evt?: EmblaEventType,
  ) => void;
  setInitialGroup: (emblaApi: EmblaCarouselType, evt?: EmblaEventType) => void;
}

export const SliderContext = React.createContext<SliderContextType>({
  onSelect: () => {},
  onInit: () => {},
  groups: [],
  selectedIndex: 0,
  scrollNext: () => {},
  nextDisabled: false,
  scrollPrevious: () => {},
  previousDisabled: false,
  toggleActiveWhenScrollable: () => {},
  setInitialGroup: () => {},
});

interface SliderProviderProps {
  children: ReactElement<{
    children: ReactElement[];
  }>;
  initialKey?: string;
  align?: 'start' | 'center' | 'end';
}

const SliderProvider = ({
  children,
  initialKey = undefined,
  align = 'start',
}: SliderProviderProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [ref, emblaApi] = useEmblaCarousel({
    align,
    slidesToScroll: 'auto',
    containScroll: 'trimSnaps',
    skipSnaps: true,
  });
  const [previousDisabled, setPrevBtnDisabled] = useState(true);
  const [nextDisabled, setNextBtnDisabled] = useState(true);
  const [groups, setGroups] = useState<number[]>([]);

  const onInit = useCallback(
    (emblaApi: EmblaCarouselType) => {
      setGroups(emblaApi.scrollSnapList());
    },
    [children],
  );

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  const scrollPrevious = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi],
  );

  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi],
  );

  const toggleActiveWhenScrollable = useCallback(
    (emblaApi: EmblaCarouselType) => {
      const isScrollable = emblaApi?.internalEngine().scrollSnaps.length > 1;
      emblaApi?.reInit({ active: isScrollable });
    },
    [emblaApi],
  );

  const setInitialGroup = useCallback(
    (emblaApi: EmblaCarouselType) => {
      const keys: (Key | null)[] = [];

      Children.forEach(children, ({ props }) => {
        props.children.forEach((props) => {
          keys.push(props.key);
        });
      });

      const internalEngine = emblaApi?.internalEngine();
      const slideRegistry = internalEngine?.slideRegistry;

      const index = initialKey ? keys.indexOf(initialKey) : 0;
      const slideIndex = slideRegistry?.findIndex((i) => i.includes(index));

      slideIndex && setSelectedIndex(slideIndex);
      emblaApi.reInit({
        startIndex: slideIndex,
      });
    },
    [emblaApi, children, initialKey],
  );

  const value = useMemo(
    () => ({
      ref,
      emblaApi,
      scrollPrevious,
      scrollNext,
      nextDisabled,
      previousDisabled,
      onSelect,
      onInit,
      groups,
      selectedIndex,
      toggleActiveWhenScrollable,
      setInitialGroup,
    }),
    [
      ref,
      emblaApi,
      scrollPrevious,
      scrollNext,
      nextDisabled,
      previousDisabled,
      onSelect,
      onInit,
      groups,
      selectedIndex,
      toggleActiveWhenScrollable,
      setInitialGroup,
    ],
  );

  return (
    <SliderContext.Provider value={value}>{children}</SliderContext.Provider>
  );
};

export default SliderProvider;
