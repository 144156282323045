import PropTypes from 'prop-types';
import React from 'react';
import PropertyContent from '@/propTypes/PropertyContent';
import getTagsFromPropertyContent from '@/utils/getTagsFromPropertyContent';
import Text from '@/components/Text';

/**
 * @deprecated Use PropertyTags.tsx
 *
 * Note: There shouldn't be a use case to use this component directly; You might be after the PropertyTitle
 *
 * import PropertyTitle from '@/v2/components/ui/PropertyTitle';
 */
const PropertyTags = React.memo(({ propertyContent, tags, size }) => {
  // use tags if available.
  const propertyTags = tags.length
    ? tags
    : getTagsFromPropertyContent(propertyContent);

  return propertyTags
    .slice(0, size ? size : propertyTags.length)
    .map((tag, i) =>
      i === 0 ? (
        <Text key={i} fontSize={['xxs', null, 'xs']}>
          {tag.name}
        </Text>
      ) : (
        <Text key={i}>
          <Text fontSize={['xxs', null, 'xs']} mx={2}>
            |
          </Text>
          <Text fontSize={['xxs', null, 'xs']}>{`${tag.name}`}</Text>
        </Text>
      ),
    );
});

PropertyTags.defaultProps = {
  tags: [],
};

PropertyTags.propTypes = {
  propertyContent: PropTypes.shape(PropertyContent).isRequired,
  tags: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.number,
};

export default PropertyTags;
