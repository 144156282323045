import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { map, pipe, uniq, unnest } from 'ramda';
import React from 'react';
import { Box, Icon, Flex } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import Destination from '@/propTypes/Destination';
import OriginSelect from '@/components/OriginSelect';
import FormControl from '@/components/FormControl';
import Select from '@/components/Select';
import Input from '@/components/Input';

const IconWrapper = styled(Flex)`
  pointer-events: none;
`;

const StyledInput = styled(Input)`
  cursor: pointer;
  color: ${themeGet('colors.text.placeHolder')};
  border-color: ${(p) =>
    themeGet(p.error ? 'colors.text.error' : 'colors.border')};
`;

/*
  Route selection:
  - From city: use originCodes from homePage.destinations[].searchConfig
  - To city: use destination list from homePage.search
*/
const Route = ({
  fromDestinations,
  toDestinations,
  route,
  onDataChange,
  onOriginFocus,
  originFocused,
  error,
}) => {
  const originCodes = pipe(
    map((d) => d.searchConfig.originCodes),
    unnest,
    uniq,
  )(fromDestinations);

  return (
    <Flex flexDirection={['column', null, 'row']}>
      <Box width="100%">
        <FormControl
          htmlFor="from"
          label="Flying from"
          data-testid="ROUTE_FROM"
        >
          <Box position="relative">
            <OriginSelect
              mb="0"
              id="from"
              data-testid="SELECT"
              codes={originCodes}
              value={route.origin}
              onChange={(origin) => onDataChange({ origin })}
              placeholder="Select location"
              error={
                (originFocused || error.origin) && 'Please select location'
              }
              readOnly
            />
            <IconWrapper
              width="48px"
              position="absolute"
              top="12px"
              right="0"
              alignItems="center"
              justifyContent="center"
            >
              <Icon size={24} name="place" />
            </IconWrapper>
          </Box>
        </FormControl>
      </Box>
      <Box mx={2} my={2} />
      <Box width="100%">
        <FormControl htmlFor="to" label="Travelling to" data-testid="ROUTE_TO">
          <Box position="relative">
            {route.origin ? (
              <Select
                mb="0"
                id="to"
                data-testid="SELECT"
                value={route.destination ? route.destination.name : ''}
                onChange={(event) => {
                  const destination = fromDestinations.find(
                    (d) => d.name === event.target.value,
                  );
                  onDataChange({ destination });
                }}
                error={error.destination && 'Please select destination'}
                readOnly
              >
                <option disabled hidden value="">
                  Select destination
                </option>
                {!toDestinations?.[0] && (
                  <option disabled value="">
                    No destination available
                  </option>
                )}
                {route.origin &&
                  unnest(toDestinations).map(
                    ({ label: region, destinations: items }) => (
                      <optgroup key={region} label={region}>
                        {items.map(({ name, title }) => (
                          <option key={name} value={name}>
                            {title}
                          </option>
                        ))}
                      </optgroup>
                    ),
                  )}
              </Select>
            ) : (
              <StyledInput
                readOnly
                value="Select destination"
                onFocus={() => {
                  onOriginFocus(true);
                }}
                onBlur={() => {
                  onOriginFocus(false);
                }}
                error={
                  error.destination && (
                    <Box mt={1}>Please select destination</Box>
                  )
                }
              />
            )}
            <IconWrapper
              width="48px"
              position="absolute"
              top="12px"
              right="0"
              alignItems="center"
              justifyContent="center"
            >
              <Icon size={24} name="place" />
            </IconWrapper>
          </Box>
        </FormControl>
      </Box>
    </Flex>
  );
};

Route.propTypes = {
  fromDestinations: PropTypes.arrayOf(PropTypes.shape(Destination)).isRequired,
  toDestinations: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      destinations: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  route: PropTypes.shape({
    origin: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string,
    }),
    destination: PropTypes.shape(Destination),
  }).isRequired,
  onDataChange: PropTypes.func.isRequired,
  onOriginFocus: PropTypes.func.isRequired,
  originFocused: PropTypes.bool,
  error: PropTypes.shape({
    origin: PropTypes.string,
    destination: PropTypes.string,
    departureDate: PropTypes.string,
    returnDate: PropTypes.string,
    route: PropTypes.string,
  }).isRequired,
};

export default Route;
