import { useMemo } from 'react';
import useDataLayer from '@/hooks/useDataLayer';
import userInteractionEvent from '@/utils/userInteractionEvent';

const useRouteDataLayer = (origin, destination, error) => {
  const event = useMemo(() => {
    if (!origin || !destination) {
      return null;
    }

    const category = 'Home Page Search';
    const action = `${origin} - ${destination}`;
    const label = error ? 'Unsupported Destination' : 'Supported Destination';

    return userInteractionEvent(label, action, category);
  }, [origin, destination, error]);

  useDataLayer(event);
};

export default useRouteDataLayer;
