import { UI } from '@/v2/types';
import Head from 'next/head';

import translations from '@/v2/locales/en/common.json';

export interface Props extends UI.Meta {
  preferenceAppTitle?: boolean;
}

const Meta = ({ description, title, canonical, preferenceAppTitle }: Props) => (
  <Head>
    <title>
      {(preferenceAppTitle
        ? [translations.app.title, title]
        : [title, translations.app.title]
      )
        .filter((title) => !!title)
        .join(' | ')}
    </title>
    <meta name="description" content={description} />
    {canonical && <link rel="canonical" href={canonical} />}
  </Head>
);

export default Meta;
