import React, { PropsWithChildren, useEffect } from 'react';
import styled from '@emotion/styled';
import useSlider from '../../hooks/useSlider';
import Navigation from '../Navigation/Navigation';

interface SliderProps
  extends PropsWithChildren<{
    Wrapper?: () => JSX.Element;
    Container?: () => JSX.Element;
    LeftButton: () => JSX.Element;
    RightButton: () => JSX.Element;
    Indicator: (props: {
      snapPointPosition: number;
      index: number;
    }) => JSX.Element;
  }> {}

const DefaultContainer = styled.div``;

const Embla = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 24px;
  padding-top: 12px;
  margin-top: -12px;

  ${DefaultContainer} {
    display: flex;
    gap: 16px;
    margin-right: 12px;

    > div {
      display: flex;
      flex: 0 0 auto;
      min-width: 0;
      // REF: https://github.com/davidjerleke/embla-carousel/issues/623
      // max-width: % (100% or less) is required or the browser will crash when the viewport is smaller than the width of the slide
      max-width: 100%;
    }
  }
`;

const Slider = ({
  children,
  LeftButton,
  RightButton,
  Indicator,
  Container,
  Wrapper,
}: SliderProps) => {
  const {
    emblaApi,
    ref,
    onSelect,
    onInit,
    groups,
    toggleActiveWhenScrollable,
    setInitialGroup,
  } = useSlider();

  const isMoreThanOneGroup = groups.length > 1;
  const WrapperEl = Wrapper || Embla;
  const ContainerEl = Container || DefaultContainer;

  useEffect(() => {
    if (!emblaApi) return;

    onInit(emblaApi);
    setInitialGroup(emblaApi);
    onSelect(emblaApi);
    toggleActiveWhenScrollable(emblaApi);
    emblaApi.on('reInit', onInit);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
    emblaApi.on('resize', toggleActiveWhenScrollable);
  }, [emblaApi, onInit, onSelect, setInitialGroup, toggleActiveWhenScrollable]);

  return (
    <WrapperEl ref={ref}>
      <ContainerEl>{children}</ContainerEl>
      {isMoreThanOneGroup && (
        <Navigation
          groups={groups}
          LeftButton={LeftButton}
          RightButton={RightButton}
          Indicator={Indicator}
        />
      )}
    </WrapperEl>
  );
};

export default Slider;
