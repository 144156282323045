import type { UI } from '@/v2/types';

import { useEffect } from 'react';
import { Box, Container, Flex, Hide } from '@qga/roo-ui/components';

import translations from '@/v2/locales/en/home.json';

import { Destinations } from '@/v2/components/ui/Destinations';
import { Hero } from '@/v2/components/ui/Hero';
import { Layout } from '@/v2/components/layout/Layout';

import { dataLayer } from './utils/dataLayer';

// Legacy components
import CallBanner from '@/v2/../components/HomePage/components/CallBanner';
import Campaigns from '@/v2/../components/HomePage/components/Campaigns';
import DealsSlider from '@/v2/../components/HomePage/components/DealsSlider';
import Heading from '@/v2/../components/Heading';
import ImportantInformation from '@/v2/../components/ImportantInformation';
import SearchForm from '@/v2/../components/HomePage/components/SearchForm';
import TermsConditions from '@/v2/../components/HomePage/components/TermsConditions';
import ValueProposition from '@/v2/../components/ValueProposition';
import usePageViewEvent from '@/v2/hooks/usePageViewEvent';

// Note: Layout called here temporarily with props
// - needs to go to _app when v2 is complete

interface Props {
  campaigns: Array<UI.Campaign>;
  homePage: UI.Home;
  importantInformation: Array<UI.ImportantInformation>;
  navigation: Array<UI.Navigation>;
  warningMessage: UI.Message | null;
  informationMessage: UI.Message | null;
  marketingMessage: UI.MarketingMessage | null;
}

const HomeView = ({ campaigns, homePage, ...rest }: Props) => {
  useEffect(() => {
    dataLayer();
  }, []);

  usePageViewEvent({ type: 'home' });

  return (
    <Layout {...rest}>
      <Box data-testid="HOME_PAGE">
        <Box bg="white" pb="6">
          <Hero {...homePage.hero} />
          <Box
            position="relative"
            top={[null, null, '-32px']}
            mb={['5', null, '-48px']}
          >
            <Container px="3" mx={['0', null, 'auto']}>
              <Box
                bg="background.card"
                borderRadius="sm"
                borderColor="border"
                mt={['4', null, '0']}
                boxShadow="light"
              >
                <Box
                  pt={['4', null, '5']}
                  pb={['4', null, '5']}
                  px={['3', null, '5']}
                >
                  <Container px="0" mx={['0', null, 'auto']}>
                    <SearchForm
                      fromDestinations={homePage.destinations}
                      toDestinations={homePage.search}
                      campaigns={campaigns}
                      isHomeSearch
                    />
                  </Container>
                </Box>
              </Box>
            </Container>
          </Box>
          {/* @ts-ignore */}
          <ValueProposition
            withTitle
            pt={[0, null, 10]}
            mt={[2, null, 4]}
            mb="6"
          />
          <Container>
            <TermsConditions />
          </Container>
        </Box>
        <Box my="6">
          <Container>
            <Campaigns />
          </Container>
        </Box>
        <Flex flexDirection="column">
          <Box zIndex="1">
            <Container boxShadow="soft">
              <Box pt={['3', null, '5']} data-testid="DESTINATIONS" mb="2">
                <Destinations
                  destinations={homePage.destinations}
                  heading={translations.sections.destinations.title}
                />
              </Box>
            </Container>
          </Box>
        </Flex>
        <Container>
          <Hide xs sm>
            <Box mt="4">
              <Heading.h2 fontSize="2xl">
                {translations.sections.packages.title}
              </Heading.h2>
            </Box>
          </Hide>
          <Hide md lg>
            <Box mt="6" mb="6">
              <Heading.h2 fontSize="md">
                {translations.sections.packages.title}
              </Heading.h2>
            </Box>
          </Hide>
          <DealsSlider propertiesContent={homePage.featuredProperties} />
        </Container>
        <Container>
          <Box mt={['8', null, '10']}>
            <CallBanner />
          </Box>
        </Container>
        <Box pt="9" mt="10" bg="background.card">
          <Container>
            <ImportantInformation />
          </Container>
        </Box>
      </Box>
    </Layout>
  );
};

export default HomeView;
