import type { UI } from '@/v2/types';
import styled from '@emotion/styled';
import { Box, Flex } from '@qga/roo-ui/components';
import { SanityImage } from '@experiences-ui/shared/components';
import ErrorBoundary from '@/libs/v2/components/ErrorBoundary';
import { dataLayer } from '@/v2/utils/dataLayer';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

// Legacy
import Heading from '@/v2/../components/Heading';
import Link from '@/v2/../components/Link';

const StyledLink = styled(Link.Text)`
  width: 100%;
`;

interface Props extends UI.HomeDestination {
  index: number;
}

const DestinationCard = ({ image, name, properties, title, index }: Props) => {
  const shouldUseNewEvents = useGA4Events();
  const propertyCount = (properties && properties.length) ?? 0;

  return (
    <ErrorBoundary>
      <Box
        bg="background.card"
        boxShadow="light"
        onClick={() => {
          if (shouldUseNewEvents) {
            dataLayer.tileClickEvent({
              groupName: title,
              itemName: `${title} Holidays`,
              itemText: propertyCount
                ? `VIEW ${propertyCount} HOLIDAYS`
                : 'VIEW HOLIDAYS',
              index,
              url: `/${name}`,
            });
          }
        }}
      >
        <StyledLink
          as={`/destinations/${name}`}
          data-testid="LINK"
          external={false}
          href={`/destinations/${name}`}
          shallow={false}
        >
          <Box height={'120px'} position={'relative'}>
            <SanityImage
              data-testid="SANITY_DESTINATION_IMAGE"
              image={image}
              alt={title}
              objectFit="cover"
              role="img"
            />
          </Box>
          <Flex flexDirection="column" p="4" height={[null, null, '122px']}>
            <Box mb="2">
              <Heading.h4>{title}</Heading.h4>
            </Box>
          </Flex>
        </StyledLink>
      </Box>
    </ErrorBoundary>
  );
};

export default DestinationCard;
