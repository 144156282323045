import React from 'react';
import PropTypes from 'prop-types';
import useOrigins from '@/hooks/useOrigins';
import Select from '@/components/Select';

const OriginSelect = ({ codes, value, onChange, placeholder, ...props }) => {
  const origins = useOrigins(codes).map((o) => ({
    ...o,
    name: `${o.name} - ${o.code}`,
  }));

  return (
    <Select
      data-testid="ORIGIN_SELECT"
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      value={value ? value.code : ''}
      onChange={(event) => {
        const origin = origins.find((o) => o.code === event.target.value);
        onChange(origin);
      }}
    >
      <option disabled value="">
        {placeholder}
      </option>
      {origins.map(({ name, code }) => (
        <option data-testid="ORIGIN" key={code} value={code}>
          {name}
        </option>
      ))}
    </Select>
  );
};

OriginSelect.defaultProps = {
  value: undefined,
  placeholder: 'Please select',
};

OriginSelect.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.shape({
    name: PropTypes.string,
    code: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default OriginSelect;
