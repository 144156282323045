import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import PropertyContent from '../../../../propTypes/PropertyContent';
import { Slider, SliderProvider } from '@/libs/v2/components/Slider';
import Item from './components/Item';
import { RightButton, LeftButton, Indicator } from '@/v2/components/ui/Slider';
import { SCREEN_SIZE } from '@/libs/v2/constants';

const Container = styled.div`
  display: flex;
  gap: 16px;
  margin-right: 13px;
  margin-top: 8px;

  ${SCREEN_SIZE.MD} {
    gap: 26px;
  }

  > div {
    display: flex;
    flex: 0 0 auto;
    min-width: 0;
    max-width: 90%;
  }
`;

const DealsSlider = ({ propertiesContent }) => {
  return (
    <SliderProvider>
      <Slider
        Indicator={Indicator}
        LeftButton={LeftButton}
        RightButton={RightButton}
        Container={Container}
      >
        {propertiesContent.map((propertyContent) => (
          <Item key={propertyContent.id} propertyContent={propertyContent} />
        ))}
      </Slider>
    </SliderProvider>
  );
};

DealsSlider.propTypes = {
  propertiesContent: PropTypes.arrayOf(PropTypes.shape(PropertyContent))
    .isRequired,
};

export default DealsSlider;
